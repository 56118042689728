//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.15);
  &.btn-secondary{
    color: $white !important;
  }
}


.btn-primary {
  background-color: rgba(var(--bs-primary-rgb), 1) !important;
  border-color: rgba(var(--bs-primary-rgb), 1) !important;
  &:hover,
  &:focus,
  &:active,
  &.disabled,
  &:disabled {
      background-color: rgba(var(--bs-primary-rgb), 1);
      border-color: rgba(var(--bs-primary-rgb), 1);
      &:focus {
          box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-primary-rgb), 0.5);
      }
  }
}
.btn-outline-primary {
  color: rgba(var(--bs-primary-rgb), 1);
  border-color: rgba(var(--bs-primary-rgb), 1);
  &:hover,
  &:focus,
  &:active,
  &.disabled,
  &:disabled {
      background-color: rgba(var(--bs-primary-rgb), 1);
      border-color: rgba(var(--bs-primary-rgb), 1);
      color: $white;
      &:focus {
          box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-primary-rgb), 0.5);
      }
  }
}
//red
.btn-danger {
  background-color: var(--#{$prefix}danger) !important;
  border-color: var(--#{$prefix}danger) !important;
  &:hover,
  &:focus,
  &:active,
  &.disabled,
  &:disabled {
      background-color: var(--#{$prefix}danger);
      border-color: var(--#{$prefix}danger);
      &:focus {
          box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-danger-rgb), 0.5);
      }
  }
}

.btn-outline-danger {
  color: var(--#{$prefix}danger);
  border-color: var(--#{$prefix}danger);
  &:hover,
  &:focus,
  &:active,
  &.disabled,
  &:disabled {
      background-color: var(--#{$prefix}danger);
      border-color: var(--#{$prefix}danger);
      color: $white;
      &:focus {
          box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-danger-rgb), 0.5);
      }
  }
}

//green
.btn-success {
  background-color: var(--#{$prefix}success) !important;
  border-color: var(--#{$prefix}success) !important;
  &:hover,
  &:focus,
  &:active,
  &.disabled,
  &:disabled {
      background-color: var(--#{$prefix}success);
      border-color: var(--#{$prefix}success);
      &:focus {
          box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-success-rgb), 0.5);
      }
  }
}

.btn-outline-success {
  color: var(--#{$prefix}success);
  border-color: var(--#{$prefix}success);
  &:hover,
  &:focus,
  &:active,
  &.disabled,
  &:disabled {
      background-color: var(--#{$prefix}success);
      border-color: var(--#{$prefix}success);
      color: $white;
      &:focus {
          box-shadow: 0 0 0 $btn-focus-width rgba(var(--bs-success-rgb), 0.5);
      }
  }
}