//accordion

.accordion {
    .accordion-item{
        color: var(--#{$prefix}gray-600);
    }
    .accordion-button:not(.collapsed) {
        --bs-accordion-active-color: var(--#{$prefix}primary);
    }
}
