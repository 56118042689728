//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}body-color);
      font-weight: $font-weight-medium;
    }
  }
  .nav-pills{
    .nav-link{
      // color: var(--#{$prefix}primary) !important;
      &.active{
        background-color: var(--#{$prefix}primary) !important;
        color: $white !important;
      }
    }
  }
  }

.nav-tabs{
  .nav-link{
    &.active{
      background-color: transparent;
      color: var(--#{$prefix}primary) !important;
    }
  }
}
.nav-tabs-custom {
  border-bottom: 2px solid $gray-300;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: var(--#{$prefix}primary);
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: var(--#{$prefix}primary) !important;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}
