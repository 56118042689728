//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 6px;
    border: none;
    line-height: 1.25;
  }
}
.page-link {
  color: var(--#{$prefix}emphasis-color);

}
.page-item.active .page-link {
  background-color: var(--#{$prefix}primary);
  border-color: var(--#{$prefix}primary);
  box-shadow: $box-shadow;
}
.page-link:focus,
.page-link:hover {
  color: var(--#{$prefix}emphasis-color);
  background-color: var(--#{$prefix}tertiary-bg);
}

.pagination {
  .page-item {
    .page-link {
      border-radius: 4px;
    }
  }
}
